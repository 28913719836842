// @import "../../../../styles/scss/globals.scss";
@import './episodes/EpisodeList.module.scss';

.imageWrap {
  img {
    position: relative;
    border-radius: 8px;
    display: block;
    @include max(767){
      margin: 0 auto 1.75rem;
    }
  }
}

.titleEditWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.mainTitle {
  margin-bottom: 0;
}

.editLink {
  font-size: 1rem;
  color: $grey;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover,
  &:focus,
  &:active {
    color: $primary;
  }
  .singleIcon {
    width: auto;
    max-width: 100%;
    height: 1.25rem;
    margin-right: 5px;
  }
  .linkText {
    line-height: 1;
  }
}

.podcastDescription {
  color: $grey;
  font-size: 1.25rem;
  //margin-bottom: 1.95rem;
  white-space: pre-wrap;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #e5e7ea;

  span[data-type="emoji"] {
    img {
      display: inline !important;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
.episodeDescription {
  a {
    color: #3d66f4;
    text-decoration: underline;
    cursor: pointer;
  }
}
.socialIconsWrap {
  margin-top: 1.95rem;
  margin-bottom: 1.95rem;
  row-gap: 2rem;
  div{
    align-content: center;
  }
  .singleIconWrap {
    margin-right: 1.25rem;
    display: inline-block;
    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
  .singleIcon {
    width: auto;
    max-width: 100%;
    height: 1.5rem;
  }
  .shareIcon {
    position: relative;
    transform: translateY(1px);
  }

  &.listenNowWrap {
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #e5e7ea;
    .singleIconWrap {
      margin-right: 1.25rem;
      display: inline-block;
      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }
    .singleIcon {
      height: 3rem !important;
    }
  }
}

.podcasterDetails {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  .imageWrap {
    img {
      width: auto;
      max-width: 100%;
      height: 3.925rem;
      margin-bottom: 0;
    }
  }
  .podcastContentWrap {
    margin-left: 1rem;
  }
  .podcasterDetailsText {
    color: $grey;
    margin: 0 auto;
    line-height: 1.6;
    font-size: 1rem;
    font-weight: 500;
  }
  .podcasterName {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
}

.statsWrapper {
  margin-top: 4rem;
  margin-bottom: 2rem;
  .single {
    background: $white;
    border: 1px solid $lightGrey;
    padding: 3rem 2rem 2rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    border-radius: 8px;
    display: flex;
    // max-width: 80%;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    text-align: left;
    min-height: 14rem;
    position: relative;
    @include transition(0.2s);
    &:hover,
    &:focus {
      transform: scale(1.065);
      box-shadow: 0px 0px 24px rgba(229, 231, 234, 0.6);
      border-color: #eee;
      background: #fafafa;
    }
  }
  .icon {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: -1px;
    left: -1px;
    background-size: contain;
    background: $secondary;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom-right-radius: 2rem;
    // transform: translate(-50%, -50%);
  }
  .number,
  .description {
    margin: 0.5rem auto;
    // margin-right: 2.5rem;
    width: 90%;
    max-width: 160px;
  }
  .number {
    color: $primary;
    font-size: 2.5rem;
    word-break: break-all;
    line-height: 1;
    margin-bottom: 5px;
    font-weight: 400;
  }
  .description {
    color: $grey;
    font-size: 1.25rem;
  }
}
.borderedWrap {
  font-size: 1.5rem;
  color: $primary;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $lightGrey;
  border-left: 0;
  border-right: 0;
  margin-bottom: 1.95rem;
  .text {
    margin: 2rem 0;
    margin-right: 2rem;
    font-weight: 600;
  }
  .light {
    color: $grey;
    font-weight: 500;
    // font-size: 1.25rem;
  }
  .amount {
    font-weight: 700;
    margin-left: 0.5rem;
  }
}
.payoutNote {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  margin-bottom: 2rem;
}
.cta {
  margin-top: 0.75rem;
}
.buttonsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &.multipleButtons {
    @include min(481) {
      justify-content: space-between;
    }
  }
  .loadMore {
    margin: 0 0.5rem;
    margin-bottom: 1.25rem;
    @include max(480) {
      width: 100%;
      &:first-child {
        order: 1;
      }
    }
  }
  button {
    min-width: 11.5rem;
  }
}

// Responsive begin

@include min(992) {
  .mainTitle {
    padding-right: 2rem;
  }
}

@include max(991) {
  .topSection {
    margin-top: 3rem;
  }
}
@include max(767) {
  .statsWrapper {
    margin-bottom: 1rem;
    .single {
      text-align: center;
      padding: 2rem 2rem;
      min-height: 10rem;
    }
  }
  .borderedWrap {
    flex-direction: column;
    padding: 30px 15px 10px;
    margin-bottom: 2rem;
    .text {
      margin: 0 auto 20px;
      text-align: center;
      display: block;
    }
  }
}

@include max(600) {
  .primary {
    position: relative;
    overflow-x: hidden;
  }
}

@include max(575) {
  .topSection {
    justify-content: center;
    margin-top: 0;
    > .imageWrap {
      margin-top: 2rem;
    }
  }
}
