@import "pages/home/Home.module.scss";

.sectionHeading {
	text-align: left;
	margin-bottom: 1.25rem;
}
.sectionSubHeading, .subheading {
	text-align: left;
	font-size: 1.25rem;
	color: $primary;
	font-weight: 500;
	margin-bottom: 1rem;
    line-height: 1.4;
	@include max(767) {
		font-size: 1rem;
	}
}
.contentWrap {
	@extend .pricingWrap;
	background: $white;
	z-index: 2;

	.contentChild {
		@extend .pricing;
		padding-top: 3rem;
		padding-bottom: 3rem;
		.backgroundText {
			top: 22rem;
			color: $background;
		}
		text-align: left;
	}
	p, li {
		font-size: 1.25rem;
		color: $grey;
		font-weight: 500;
		line-height: 1.4;
		@include max(767) {
			font-size: 1rem;
		}
	}
	a {
		color: $primary;
	}
	.date {
		margin-bottom: 1.5rem;
	}
	strong, b {
		font-weight: 600;
	}
	h2, h3, h4, h5 {
		font-size: 1.75rem;
		color: $primary;
		font-weight: 600;
		margin-top: 2rem;
	}
	h3, h4 {
		font-size: 1.5rem;
		font-weight: 500;
	}
	.errorContent {
		text-align: center;
		margin-top: 5rem;
		min-height: 50vh;
		.sectionHeading {
			width: 100%;
			text-align: center;
		}
		.btn {
			margin-top: 1rem;
		}
		@include max(991) {
			margin-top: 0;
		}
	}
	.mainError {
		margin-top: 0;
		.sectionHeading {
			font-size: 2.5rem;
		}
		p {
			font-size: 1rem;
		}
		.backgroundText {
			top: 17rem;
			right: auto;
			left: 0;
			transform: translate(-50%, -30%) rotate(-90deg);
		}
	}
	.terms {
		padding-top: 1rem;
	}
}
.contactPage {
	.sectionHeading, .formGroup {
		margin-bottom: 1.5rem;
	}
	.contentChild {
		max-width: 33rem;
		.backgroundText {
			top: 16rem;
			left: -80%;
			right: auto;
		}
		@include max(991) {
			padding-bottom: 0;
		}
	}
	.textarea {
		min-height: 14rem !important;
	}
	.rightBtn {
		display: block;
		margin-left: auto;
		margin-right: auto;
		min-width: 11.6875rem;
		@include min(992) {
			margin-right: 0;
		}
	}
}
.helpPage {
	@extend .contactPage;
	.contentChild {
		padding-top: 2rem;
	}
	.sectionHeading {
		margin-bottom: 1rem;
	}
	.description {
		font-size: 1rem;
		margin-bottom: 1.5rem;
	}
	.textarea {
		min-height: 8.5rem !important;
	}
}
