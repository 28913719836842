@import "../[podcast-name]/SinglePublicPodcast.module.scss";

.primary {
	padding: 0;
}
.description {
	font-size: 1rem;
	font-weight: 500;
	color: $grey;
}
.sectionHeading {
	font-size: 3rem;
	text-align: center;
	font-weight: 600;
	color: $primary;
	margin-bottom: 1rem;
}
.sectionSubHeading {
	font-size: 1.25rem;
	text-align: center;
	font-weight: 500;
	color: $grey;
	margin-bottom: 1rem;
}
.backgroundText {
	color: $background;
	font-size: 10.625rem;
	font-weight: 800;
	position: absolute;
	top: 50%;
	right: -39.25%;
	line-height: 0;
	transform: translate(0%,-50%) rotate(-90deg);
	z-index: -1;
}
.wideCols {
	> div {
		@include min(1142) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
.leftShort {
	max-width: 31rem;
	margin-right: 0;
	margin-left: auto;
	> * {
		max-width: 26rem;
	}
}
.leftShortBig {
	// max-width: 76.5rem;
	// max-width: 86.25rem;
	max-width: 90%;
	margin-right: 0;
	margin-left: auto;
}
.hero {
	padding-top: 0.5rem;
	.backgroundText {
		top: 62.85%;
		left: -10.5rem;
		right: auto;
	}
	.heroWrapper {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		// height: 70vh;
		// min-height: 35rem;
		overflow: hidden;
	}
	.sectionHeading, .description {
		margin-bottom: 1.25rem;
	}
	.sectionHeading {
		text-align: left;
		font-size: 4rem;
		line-height: 1.2;
		// line-height * number of lines needed * 1em
		min-height: 1.2 * 4 * 1em;
		@include max(991) {
			font-size: 3rem;
			min-height: 1.2 * 2 * 1em;
		}
		@include max(480) {
			font-size: 2.5rem;
		}
	}
	.description {
		font-size: 1.25rem;
	}
	.leftShort {
		padding-top: 4rem;
		padding-bottom: 4rem;
		@include max(991) {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
	.middleWrapper {
		position: relative;
		height: calc(100vh - 105px);
		min-height: 41.25rem;
		// position: relative;
		display: flex;
		align-items: center;

		@include max(991) {
			max-height: 44.25rem;
		}

		.heroScrollLink {
			display: none;
			@include max(991) {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute !important;
				top: 10.5%;
				left: 4%;
				font-size: 0.875rem;
				font-weight: 600;
				transform: rotate(-90deg);
				&::before {
					transform: rotate(-180deg);
					margin: 0 auto;
					margin-right: 0.65rem;
					top: -1px;
					width: 1.15rem;
					height: 1.15rem;
				}
				&:hover, &:focus, &:active {
					opacity: 0.8;
					transform: rotate(-90deg) translateX(-0.5rem);
					color: $primary;
				}
			}
			@include max(767) {
				left: 3%;
			}
			@include max(480) {
				left: 0;
			}
		}
		.bgImageIcon {
			background-size: 100%;
			background-position: center;
			background-repeat: no-repeat;
			width: 18.125rem;
			height: 22.5625rem;
			position: absolute;
			left: 60%;
			top: 50%;
			transform: translate(-50%, -50%);
			// z-index: 1;
			@include max(991) {
				left: 80%;
			}
		}
	}
	.rightWrapper {
		align-self: stretch;
		//@include max(991) {
		//	padding-right: 0;
		//}
	}

	.right {
		display: flex;
		align-items: stretch;
		border-top-left-radius: 6.25rem;
		border-bottom-right-radius: 6.25rem;
		position: relative;
		//overflow: hidden;
		margin-right: 7.5%;
		height: 100%;
		@include max(991) {
			margin-right: 0;
		}
		.bgImg {
			background-size: 100%;
			overflow: visible;
			width: 20rem;
			height: 25rem;
			background-position: center;
			background-repeat: no-repeat;
			position: absolute;
			left: -4rem;
			top: 0;
			@include max(991) {
				background-size: contain;
				height: 100%;
				bottom: unset;
				left: 0;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}

		.imgWrapper {
			position: relative;
			padding: 4rem 2rem;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			video, img {
				max-width: 100%;
				height: auto;
				border-radius: 2px;
				box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.16);
			}

			@include max(991) {
				padding: 1rem 0.5rem
			}
		}
	}

	.bgImage {
		width: 80%;
		height: calc(100vh - 105px);
		min-height: 41.25rem;
		position: relative;
		background-size: contain;
		background-position: center top;
		background-repeat: no-repeat;
		background-blend-mode: saturation;
		display: flex;
		align-items: flex-end;
		@include max(991) {
			width: 100%;
			max-height: 44.25rem;
		}
		.bottomBtn {
			position: relative;
			display: block;
			margin: 0 auto;
			width: 100%;
			text-align: center;
			padding: 3.0655rem 15px;
			//background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
			// max-width: 17.1875rem;
			.wavesImg {
				margin: 0 1rem;
				@include transition(0.25s);
				&:hover, &:focus {
					transform: scale(1.085);
					opacity: 0.8;
				}
				&.playIcon {
					&:hover, &:focus {
						transform: scale(1.15) rotate(360deg);
						// opacity: 1;
					}
				}
			}
		}
	}
}
.aboutMumbler {
    padding-top: 8rem;
    padding-bottom: 8rem;
	color: $primary;
	.smallTitle {
		font-size: 0.875rem;
		font-weight: 700;
	}
	.left {
		position: relative;
		&::after {
			content: '';
			position: absolute;
			width: 1px;
			height: 90%;
			background: $lightGrey;
			top: 50%;
			right: 13.75%;
			line-height: 0;
			transform: translate(-50%, -50%);
			@include max(991) {
				content: none;
			}
		}
		.heading {
			font-size: 3rem;
			font-weight: 600;
			// border-right: 1px solid $lightGrey;
			@include max(480) {
				font-size: 2rem;
				margin-bottom: 1rem;
			}
		}
	}
	.description {
		margin-top: 0.4rem;
		font-size: 1.25rem;
	}
}
.whyUs {
	background: $background;
	.reasonsTitleWrapper {
		padding-top: 6.85rem;
		padding-bottom: 3.5rem;
	}
	.sectionHeading {
		text-align: left;
	}
	.sectionSubHeading {
		text-align: left;
	}
	.reasonsWrapper {
		padding-bottom: 6.85rem;
		.heading {
			font-size: 2rem;
			color: $primary;
			font-weight: 600;
			margin-bottom: 1.65rem;
			line-height: 2.1;
		}
		.left {
			//margin-top: 4rem;
		}
		.description {
			font-size: 1.25rem;
		}
		.highlight {
			border-bottom: 3px solid $secondary;
			padding-bottom: 1.15rem;
		}
		.bgImage {
			width: 100%;
			height: 26.8125rem;
			background-size: cover;
			background-position: center 15%;
			background-repeat: no-repeat;
			filter: grayscale(1);
		}
		.rightWrapper {
			padding-right: 0;
		}
		.rightWrapper:nth-child(2) .bgImage {
			border-top-left-radius: 6.25rem;
		}
		.rightWrapper:nth-child(3) .bgImage {
			border-top-left-radius: 6.25rem;
			border-bottom-right-radius: 6.25rem;
		}

		.imgWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			padding: 1rem 2rem;
			margin-right: 7.5%;

			video, img {
				max-width: 100%;
				height: auto;
				border-radius: 2px;
                box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.16);
			}
			@include max(991) {
				margin-right: 0;
			}
		}
	}
}
.howItWorks {
	position: relative;
	padding-top: 6rem;
	padding-bottom: 2.5rem;
	padding-left: 0;
	padding-right: 0;
}
.statsWrapper {
	margin: 3rem auto 2rem;
	&.wideCols {
		@include min(1142) {
			> .left {
				padding-left: 0;
				padding-right: 1rem;
			}
			> .right {
				padding-left: 1rem;
				padding-right: 0;
			}
		}
	}
	.single {
		background: $white;
		border: 1px solid $lightGrey;
		padding: 3rem 2rem 2rem;
		margin: 0 auto;
		margin-bottom: 2.5rem;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		font-weight: 500;
		text-align: left;
		min-height: 14rem;
		position: relative;
		@include transition(0.2s);
		&:hover, &:focus {
			transform: scale(1.065);
			box-shadow: 0px 0px 24px rgba(229, 231, 234, 0.6);
			background: #fafafa;
		}
	}
	.icon {
		position: absolute;
		width: 4rem;
		height: 4rem;
		top: -1px;
		left: -1px;
		background-size: contain;
		background: $secondary;
		background-position: center center;
		background-repeat: no-repeat;
		border-bottom-right-radius: 2rem;
		// transform: translate(-50%, -50%);
	}
	.heading, .description {
		margin: 0.5rem auto;
		width: 90%;
		max-width: 18rem;
		font-size: 1.5rem;
	}
	.heading {
		color: $primary;
		font-weight: 600;
		margin-top: 0;
	}
	.description {
		font-size: 1.25rem;
	}
}
.featuredPodcasts {
	@extend .whyUs;
	padding-top: 1rem;
	padding-bottom: 7rem;
	background: $white;
	.featuredPodcastsTitleWrapper {
		@extend .reasonsTitleWrapper;
		@include max(767) {
			padding-top: 0;
		}
	}
	.featuredPodcastsWrapper {

	}
	@include max(767) {
		padding-bottom: 1rem;
	}
}
.bigCtaWrap {
	@include min(1142) {
		padding-left: 0;
		padding-right: 0;
	}
	.bigCta {
		padding-top: 6rem;
		padding-bottom: 6rem;
		background-size: 15rem;
		margin: 0 auto;
		// &::before {
		// 	content: none;
		// }
		@include min(1142) {
			padding-left: 0;
		}
		@include max(767) {
			padding-top: 3rem;
			padding-bottom: 2rem;
		}
		&:hover, &:focus, &:active {
            &::before {
                background: $background;
            }
        }
		&::after {
			background-size: 42%;
			right: -10%;
		}
		.textWrap {
			max-width: 38rem;
		}
		.text {
			font-weight: 600;
			margin-bottom: 1rem;
			font-size: 3rem;
		}
		.description {
			font-size: 1.25rem;
		}
	}
}

.pricingWrap {
	background: $background;
	position: relative;
	z-index: 0;
	.backgroundText {
		color: $white;
		z-index: 0;
	}
	.pricing {
		position: relative;
		padding-top: 5rem;
		padding-bottom: 5rem;
		margin: 0 auto;
	}
	.pricingBox {
		background: $white;
		margin: 2rem auto 0;
		padding: 2.5rem 4.5rem;
		max-width: 43.5rem;
	}
	.topText, .pricingFeatures {
		max-width: 31rem;
		margin: 0 auto;
	}
	.topText {
		text-align: center;
	}
	.commissionText {
		font-size: 3rem;
		font-weight: 600;
		color: $primary;
		margin-bottom: 0.5rem;
		line-height: 1.2;
	}
	.description {
		font-size: 1.25rem;
		margin-bottom: 1rem;
	}
	.termsText {
		font-weight: 700;
		font-size: 0.875rem;
		margin-bottom: 0;
	}
	.pricingFeatures {
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 1px solid $lightGrey;
		.left {
			padding-right: 1rem;
		}
		.right {
			padding-left: 1rem;
		}
	}
	.listWrapper {
		position: relative;
	}
	.singleItem {
		font-size: 1.25rem;
		color: $grey;
		font-weight: 500;
		margin-bottom: 1.5rem;
		&::before {
			content: "✓";
			position: relative;
			color: $secondary;
			font-family: Arial, Helvetica, sans-serif;
			margin-right: 0.25rem;
			@include max(991) {
				margin-right: 0.45rem;
			}
		}
	}
}

.faq {
	padding-top: 2rem;
	padding-bottom: 8rem;
	.sectionHeading {
		text-align: left;
	}
}
.backgroundText.priceSection{
	top: 50%;
	left: 0;
	right: auto;
	text-transform: uppercase;
	transform: translate(-50%, -50%) rotate(-90deg)
}
.priceComponent{
	padding: 32px 32px 8px;
	max-width: 416px;
	margin: auto;
	border: 1px solid #e5e7ea;
	.sectionHeading{
		font-size: 24px;
		font-weight: 600;
	}
	.pricingFeatures {
		.left {
			padding-right: 1rem;
		}
		.right {
			padding-left: 1rem;
		}
	}
	ul{
		margin-bottom: 0;
	}
	.singleItem {
		font-size: 14px;
		color: $grey;
		font-weight: 500;
		margin-bottom: 1.5rem;
		&::before {
			content: "✓";
			position: relative;
			color: $secondary;
			font-family: Arial, Helvetica, sans-serif;
			margin-right: 0.25rem;
			@include max(991) {
				margin-right: 0.45rem;
			}
		}
	}
}

.pricingPage {
	.faq {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.howItWorks {
		padding-top: 5rem;
		padding-bottom: 1.5rem;
	}
}

// Responsive begin

@include max(1140) {

}
@include max(991) {
	.leftShort {
		max-width: 92.5%;
		margin-right: auto;
		margin-left: auto;
		> * {
			max-width: 100%;
		}
	}
	.aboutMumbler {
		.smallTitle {
    		padding-left: 15px;
			padding-right: 15px;
		}
		.leftShort {
			max-width: 100%;
		}
	}
	.hero {
		.bgImage, .middleWrapper {
			height: calc(30rem - 105px);
			min-height: 55vh;
			.bgImageIcon {
				width: 14.125rem;
    			height: 17.5625rem;
			}
			@include max(480) {
				min-height: 45vh;
				.bgImageIcon {
					width: 10.125rem;
					height: 13.5625rem;
				}
			}
		}
	}
	.howItWorks {
		padding-right: 15px;
		padding-left: 15px;
	}
	.whyUs {
		.reasonsWrapper {
			.rightWrapper {
				padding-left: 0;
				margin-top: 1.5rem;
			}
		}
	}
	.backgroundText {
		display: none;
	}
	.sectionHeading, .sectionSubHeading {
		text-align: left;
	}
	.statsWrapper {
		.left, .right {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.hero .bgImage .bottomBtn {
		padding: 1.0655rem 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: row;
		.wavesImg {
			margin: 0;
			width: 50%;
			&.playIcon {
				width: 40%;
				margin-right: 1rem;
			}
		}
	}
} // max 991

@include max(767) {
	.statsWrapper {
		margin-bottom: 1rem;
		.single {
			text-align: center;
			padding: 3rem 2rem;
			min-height: 10rem;
		}
	}
	.heroWrapper {
		.heroBtn {
			width: auto;
		}
	}
	.pricingWrap {
		.pricing {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.faq {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
	.bigCtaWrap {
        .bigCta {
			padding-left: 0;
			padding-right: 0;
            .text {
                font-size: 2rem;
            }
        }
	}
	.leftShortBig {
		max-width: 100%;
		margin-right: auto;
	}
} // 767 end

@include max(480) {
	.heroWrapper {
		.description {
			br {
				display: none;
			}
		}
	}
	.aboutMumbler {
		padding-top: 5rem;
		padding-bottom: 1rem;
	}
	.sectionHeading {
		font-size: 2rem;
	}
	.whyUs {
		.reasonsTitleWrapper {
			padding-bottom: 1.25rem;
		}
		.reasonsWrapper {
			.heading {
				font-size: 1.38rem;
			}
			.description {
				font-size: 1rem;
			}
		}
	}
	// .featuredPodcasts {
	// 	.featuredPodcastsTitleWrapper {
	// 		padding-bottom: 5rem;
	// 	}
	// }
	.statsWrapper {
		.icon {
			position: absolute;
			width: 3rem;
			height: 3rem;
			background-size: 1.2rem;
		}
		.single {
			text-align: left;
			padding: 3rem 2.5rem 2rem;
		}
	}
	.pricingWrap {
		.pricingBox {
			padding: 2.5rem 1.25rem;
		}
	}
}
