// @import "../../../styles/scss/globals.scss";
@import '../account/podcasts/[podcast-name]/SinglePodcast.module.scss';

.renewInfo {
  color: $grey;
  font-weight: 500;
  margin: 1rem 0;
}

.podcatcherWarning {
  color: $primary;
  font-weight: 500;
  font-size: 1.2rem;
}

.primary {
  padding: 0;
  position: relative;
  overflow-x: hidden;
}
.mainRow {
  position: relative;
  // overflow-x: hidden;
  > .sidebar {
    position: relative;
    padding: 0;
    background: $white;
    z-index: 1;
  }
}
.topSection {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.subheading {
  font-size: 2rem;
  color: $black;
  font-weight: 600;
  margin-bottom: 2rem;
}
.mainTitleLink {
  &:hover,
  &:focus,
  &:active {
    color: $primary;
    opacity: 0.8;
  }
}
.singleEpisodeCard {
  display: flex;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid $lightGrey;

  .leftCover {
    margin-right: 2rem;
    align-self: flex-start;
    position: relative;
    @include transition(0.2s);
    .featuredImage {
      width: 12rem;
      height: 12rem;
      border-radius: 8px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border-radius: 8px;
        @include transition(0.3s);
      }
    }
    &.play{
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        .featuredImage::before {
          background: $secondary;
          opacity: 0.75;
          @include max(767) {
            display: none;
          }
        }
      }
    }
  }

  .rightDetails {
    max-width: 50rem;
    .title {
      @include transition(0.2s);
      font-size: 1.5rem;
      color: $primary;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 0.85rem;
    }
    .titleLink {
      display: block;
      &:hover,
      &:focus,
      &:active {
        opacity: 0.7;
        // .title {
        //     color: $secondary;
        // }
      }
    }
    .description {
      font-size: 1.25rem;
      color: $grey;
      font-weight: 500;
      text-align: justify;
      margin-bottom: 0.85rem;
      word-break: break-word;
      white-space: pre-wrap;
      @include max(767) {
        text-align: left;
      }
    }
    .free {
      @extend .description;
      color: $secondary;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .cta {
      .btn {
        padding: 0.595rem 1.5rem;
        @include max(767) {
          width: auto;
        }
      }
    }
  }
}

.bigCtaWrap {
  z-index: 1;
  margin-bottom: 3rem;
  position: relative;
  .bigCta {
    padding: 2.25rem 1rem;
    position: relative;
    &::before {
      content: '';
      background: $background;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 200vw;
      height: 100%;
      z-index: -1;
      @include transition(0.2s);
    }
    &:hover,
    &:focus,
    &:active {
      &::before {
        background: #ecf3ffbb;
      }
    }
    &::after {
      content: '';
      background: url('/favicon-half.webp');
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-size: 8rem;
      background-repeat: no-repeat;
      background-position: 100% bottom;
      @include max(991) {
        content: none;
      }
    }
    .text {
      color: $primary;
      position: relative;
      font-size: 2rem;
      font-weight: 600;
      margin: 0 auto;
      line-height: 1.4;
      z-index: 10;
    }
  }
  .ctaLink {
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
}
.loadMore {
  text-align: center;
  margin-bottom: 3rem;
}

// Responsive begin

@include max(991) {
  .mobileIcon {
    height: 5.75rem;
    width: auto;
    display: block;
    position: relative;
    margin-bottom: 0.75rem;
  }
}
@include max(767) {
  .singleEpisodeCard {
    flex-direction: column;
    .leftCover {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      margin-right: 0;
      width: 100%;
      .featuredImage {
        width: 6rem;
        height: 6rem;
      }
    }
  }
}

.spaciousCta {
  height: 15%;

  @media screen and (min-width: 420px) {
    height: 12%;
  }
}
