@import "../../../../../styles/scss/globals.scss";

.mainTitle {
    @extend .main-title;
    margin-bottom: 2rem;
}
.linkWrapper {
    margin-bottom: 1.5rem;
}

.singleLink {
    display: inline-block;
    color: $primary;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-left: 1px solid transparent;
    @include transition(0.2s);
    &:hover {
        color: $primary;
        border-color: $primary;
        opacity: 0.8;
    }
    &:before {
        content: none !important;
    }
    &.active {
        border-color: $secondary;
    }
}

// Responsive begin

@include min(992) {
    .sideLinks {
        position: sticky;
        top: 5%;
        z-index: 2;
        max-height: 80vh;
        overflow-y: auto;
    }
}

@include max(991) {
    .EpisodeList {
        max-width: 92.5%;
        margin: 0 auto;
        margin-top: 2rem;
    }
    .singleLink {
        border: 0;
        border-bottom: 3px solid transparent;
    }
    .sidebar {
        padding: 0;
    }
    .sideLinks {
        display: flex;
        justify-content: center;
        align-items: center;
        .linkWrapper {
            flex: 1;
            text-align: center;
            margin-bottom: 0;
        }
        .singleLink {
            width: 100%;
            display: block;
            padding: 1rem;
        }
    }
    .mainTitle {
        margin-bottom: 1rem;
    }
}